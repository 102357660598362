import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";
import {ILoginResponse, IResponse, User} from "./interfaces/response";
import {HttpClient} from "@angular/common/http";
import {WINDOW} from "./window";

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(private http:HttpClient, @Inject(WINDOW) private window: Window) {}

    _loginUrl: string = UserService.getHostname() + "/login/standard";
    _yardstickLoginUrl: string = UserService.getHostname() + "/login/yardstick";
    _signUpUrl: string = UserService.getHostname() + "/login/create";
    _resultsUrl: string = UserService.getHostname() + "/user/dashboard";
    _profileUrl: string = UserService.getHostname() + "/user/profile/";
    _editProfile: string = UserService.getHostname() + "/user/edit";
    _compareUrl: string = UserService.getHostname() + "/comparisons";
    _authUrl: string = UserService.getHostname() + "/login/authenticate";
    _logoutUrl: string = UserService.getHostname() + "/login/logout";
    _captureEmailUrl: string = UserService.getHostname() + "/user/yardstick_signup";
    public loggedIn = new Subject<boolean>();
    public user: User|any;

    login(email: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._loginUrl, {email: email, password: password}, {})
    }
    yardstickLogin(token: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._yardstickLoginUrl, {token: token}, {})
    }
    editName(displayName: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._editProfile, {display_name: displayName}, {})
    }
    captureEmail(email: string): Observable<any> {
        return this.http.post<any>(this._captureEmailUrl, {email: email}, {})
    }
    signup(email: string, password: string): Observable<ILoginResponse> {
        return this.http.post<ILoginResponse>(this._signUpUrl, {email: email, password: password}, {})
    }
    results(): Observable<any> {
        return this.http.get<any>(this._resultsUrl, {observe: 'response'})
    }
    getProfile(uid?: any): Observable<IResponse> {
        let user_id = uid || ''
        return this.http.get<IResponse>(this._profileUrl + user_id, {})
    }
    getComparison(compareId: any): Observable<IResponse> {
        return this.http.get<IResponse>(`${this._compareUrl}/${compareId}`, {})
    }
    getAuth(): Observable<any> {
        return this.http.get<boolean>(this._authUrl, {})
    }

    logout(): Observable<boolean> {
        return this.http.get<boolean>(this._logoutUrl, {})
    }

    isLoggedIn(): Observable<boolean>{
        return this.loggedIn
    }

    static getHostname() {
        if (window.location.hostname == 'localhost') {
			return 'http://localhost:8181'
		} else {
			return 'https://api.comparebench.com'
		}
    }
}
