import {AfterViewInit, Component} from '@angular/core';
import {UserService} from "./user.service";
import {User} from "./interfaces/response";
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
    public initLoading = true;
    constructor(private userService: UserService, public auth: AuthService) {

    }

    ngAfterViewInit() {
        this.userService.getAuth().subscribe(authResponse => {
            if (authResponse['authenticated'] === false) {
                this.auth.idTokenClaims$.subscribe(idtoken => {
                    if(idtoken) {
                        this.userService.yardstickLogin(idtoken.__raw).subscribe(response => {
                            this.userService.user = new User(response)
                            this.userService.loggedIn.next(true);
                            console.debug("AUTHENTICATED")
                        })
                    }
                    else{
                        console.debug("NOT AUTHENTICATED")
                        this.userService.loggedIn.next(false);
                    }
                })
            } else {
                this.userService.user = new User(authResponse)
                this.userService.loggedIn.next(true);
                console.debug("AUTHENTICATED")
            }
            this.initLoading = false
        })
    }
}
