import { NgModule } from '@angular/core';
import {Routes, RouterModule, Router, NavigationEnd} from '@angular/router';
import {LandingComponent} from "./landing/landing.component";
import {BenchmarkProfileComponent} from "./benchmark-profile/benchmark-profile.component";
import {LoginComponent} from "./auth/login/login.component";
import {BenchmarkCreationComponent} from "./benchmark-creation/benchmark-creation.component";
import {SignupComponent} from "./auth/signup/signup.component";
import {RoadmapComponent} from "./roadmap/roadmap.component";
import {ComparisonCreationComponent} from "./comparison-creation/comparison-creation.component";
import {ResultsComponent} from "./results/results.component";
import {AdminCreateProductComponent} from "./admin-create-product/admin-create-product.component";
import {AdminGuard} from "./admin.guard";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {YardstickLandingComponent} from "./yardstick-landing/yardstick-landing.component";
import {FaqComponent} from "./faq/faq.component";
import {GamePerformanceComponent} from "./game-performance/game-performance.component";
import {GamePerformanceListComponent} from "./game-performance-list/game-performance-list.component";
import posthog from "posthog-js";

const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
        // resolve: {
        //       user: AppResolverService
        //     },
        pathMatch: 'full'
    },

    {
        path: 'benchmark-creation/add',
        component: BenchmarkCreationComponent,
        pathMatch: 'full'
    },
    {
        path: 'build/:resultId',
        component: BenchmarkProfileComponent,
        pathMatch: 'full'
    },
    {
        path: 'compare/add',
        component: ComparisonCreationComponent,
        pathMatch: 'full'
    },
    {
        path: 'games',
        component: GamePerformanceListComponent,
        pathMatch: 'full'
    },
    {
        path: 'games/:gameId',
        component: GamePerformanceComponent,
        pathMatch: 'full'
    },
    // {
    //     path: 'login',
    //     component: LoginComponent,
    //     pathMatch: 'full'
    // },
    // {
    //     path: 'signup',
    //     component: SignupComponent,
    //     pathMatch: 'full'
    // },
    {
        path: 'roadmap',
        component: RoadmapComponent,
        pathMatch: 'full'
    },

    {
        path: 'results',
        component: ResultsComponent,
        pathMatch: 'full'
    },
    {
        path: 'yardstick',
        component: YardstickLandingComponent,
        pathMatch: 'full'
    },
    {
        path: 'faq',
        component: FaqComponent,
        pathMatch: 'full'
    },
    {
        path: 'admin/create-product',
        component: AdminCreateProductComponent,
        pathMatch: 'full',
        canActivate: [AdminGuard]
    },
    { path: 'dashboard', loadChildren: () => import('./dashboard-module/dashboard.module').then(m => m.DashboardModule) },
    { path: 'profile', loadChildren: () => import('./profile-module/profile.module').then(m => m.ProfileModule) },
    { path: 'compare', loadChildren: () => import('./comparison-module/comparison-module.module').then(m => m.ComparisonModule) },
    { path: 'products', loadChildren: () => import('./product-pages/product-pages.module').then(m => m.ProductPagesModule) },
    { path: 'benchmark', loadChildren: () => import('./add-benchmark-module/add-benchmark.module').then(m => m.AddBenchmarkModule) },
    { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule{
    constructor(private router: Router) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                posthog.capture('$pageview');
            }
        });
    }
}

