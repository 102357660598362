<div class="" style="background-color: #292a2d;">
                        <div style="display: flex;justify-content: center;gap: 20%;padding-top: 25px;padding-bottom: 25px">


                            <div>
                                <h4>Contact Us</h4>
                                <!-- Contact -->
                                <ul class="list-style-none">
                                    <li><a href="https://discord.gg/V3nRgYA" target="_blank"><i class="fa-brands fa-discord text-success"></i> Discord</a></li>
                                    <li><a href="https://bsky.app/profile/comparebench.com" target="_blank"><i class="fa-brands fa-bluesky text-success"></i> Bluesky</a></li>
<!--                                    <li><a href="https://www.reddit.com/r/comparebench/" target="_blank"><i class="fa-brands fa-reddit-alien text-success"></i> Reddit</a></li>-->
                                    <li><a href="https://github.com/Comparebench" target="_blank"><i class="fa-brands fa-github text-success"></i> GitHub</a></li>
                                </ul>

                            </div>
                            <div>
                                <h4>Built With</h4>
                                <!-- List style -->
                                <ul class="list-style-none">
                                    <li><a href="https://www.python.org" target="_blank"><i class="fa-brands fa-python text-success"></i> Python</a></li>
                                    <li><a href="https://github.com/pallets/flask" target="_blank"><i class="fa-solid fa-link text-success"></i> Flask</a></li>
                                    <li><a href="https://angular.io" target="_blank"><i class="fa-brands fa-angular text-success"></i> Angular</a></li>
                                    <li><a href="https://www.chartjs.org" target="_blank"><i class="fa-solid fa-chart-pie text-success"></i> ChartJS</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
